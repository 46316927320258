/* @flow */

import React, { Component } from 'react';
import {
  Alert,
  Image,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import _ from 'lodash';
import Images from '@assets/Images';
import ClientDetail from './ClientDetail';
import { LAST_CART_REQUEST } from '~/store/ordersFetch/lastCartSlice';
import {
  orderClient,
  updateStatusOpenOrder,
  ORDER_FETCH_SUCCESS,
  ORDER_FETCH_REQUEST,
  updateOrderScreen,
  getCartFetch,
} from '../../store/ordersFetch/orderFetchSlice';
import { updateItemFetch } from '../../store/ordersFetch/updateItemSlice';
import {
  PRODUCTS_MAGNITUDES_FAILURE,
  PRODUCTS_MAGNITUDES_SUCCESS,
} from '../../store/productsMagnitudes/slice';
import activeCart from '../activeCart/activeCart';
import {
  SCREEN_CLIENT_DETAIL,
  SCREEN_COMPOUND_PRODUCT,
  SCREEN_PRODUCT_DETAIL,
  SCREEN_RESUME_ORDER,
  SCREEN_WEBVIEW,
} from '../screens';
import openMaps from '../../common/openMaps';
import { SYNC_PROCESS_SALES } from '../../constants/Sync';
import { syncUpdateScreen } from '../../store/syncFetch/slice';
import { setPushingScreen } from '../../store/pushingScreen/slice';
import {
  setCurrentClient,
  setCurrentClientNoThunk,
} from '../../store/clientCurrent/slice';
import GpsHelper from '../../utils/GpsHelper';
import OrderModule from '../../modules/OrdersModule';
import GeneralModule from '../../modules/GeneralModule';
import executeActionOrOpenScreenMessage from '../../utils/messages';
import translate from '../../locales';
import verifyOrderPending from '../../utils/orderUtils';
import ActivitiesScreen from '../Activities/handleScreen';
import moment from 'moment';
import Touchable from 'react-native-platform-touchable';
import BottomSheet from '~/components/BottomSheet';
import AddrAlternativeForm from '~/components/AddressAlternative/AddressAlternativeForm';
import { color } from '~/common';
import { fonts } from '~/utils/Fonts';
import SaleOpportunityCache from '~/cache/SaleOpportunityCache';
import Snackbar from 'react-native-snackbar';
import NavigationHelper from '../NavigationHelper';
import { setModuleCurrent } from '~/store/makeOrderModule/action';
import onPressHelper from '~/utils/onPressHelper';
import {
  SAVE_CART_FETCH_REQUEST,
  saveCart,
} from '~/store/ordersFetch/saveCartSlice';
import { MODULE_ACTIVITIES } from '~/store/makeOrderModule/slice';

import GPSManager from '~/controllers/GPS/GPSManager';
import { FAMILY_SCALE_VALIDATION } from '~/utils/MessageCode';
import { setCurrentProduct } from '~/store/productCurrent/slice';
import listGeneric from '~/services/resources/libFastSeller/listGeneric';
import { emp2long } from '~/utils/FastSellerUtils';

import PropTypes from 'prop-types';
import { createNotifyError } from '~/components/Web/ToastNotify';

import { handleKeyDown, setActionsMap } from '~/utils/KeyBindingsUtils';

class ClientDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clientCurrent: props.route.params.clientCurrent,
      currentConditionsSale: {},
      clientModule: null,
      location: {
        accuracy: Infinity,
        altitude: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: 0.0,
        timestamp: 0,
      },
      alertOrderPending: false,
      totalOrder: null,
    };
    this.salesOpportunitySelected = props?.salesOpportunity?.selected;

    this.RbSheetRef = React.createRef();
    this.gpsManager = new GPSManager();

    this.clientOrderPending = null;
    this.codigoOrderPending = null;

    this.props.navigation.addListener('focus', async () => {
      window.addEventListener('keydown', handleKeyDown);
    });

    this.props.navigation.addListener('blur', () => {
      console.log('HomeContainer | blur');
      window.removeEventListener('keydown', handleKeyDown);
    });
  }

  componentDidMount() {

    setActionsMap({
      Voltar: () => this.handleBackPress(),
      Carrinho: () => this.onPressOpenCart(),
    });

    this.setRightButtons();
    this.setLeftButtons();
  }

  handleBackPress() {
    this.props.navigation.pop();
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    (async () => {
      if (
        nextProps.productsMagnitudes.type === this.props.productsMagnitudes.type
      ) {
        return;
      }
      if (nextProps.productsMagnitudes.type === PRODUCTS_MAGNITUDES_SUCCESS) {
        const { payload } = nextProps.order;
        const { codigo } = payload;

        const currentScreen = this.props.currentScreenVisible.screen;
        if (currentScreen === this.props.componentId) {
          await this.saveEventOpenOrder(codigo);
          this.openCartScreen();
        }
        this.setState({ loading: false });
      } else if (
        nextProps.productsMagnitudes.type === PRODUCTS_MAGNITUDES_FAILURE
      ) {
        this.setState({ loading: false });
        this.pushingScreen = false;
        createNotifyError(nextProps.productsMagnitudes.payload[0].mensagem);
      }
    })();

    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== ORDER_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.type !== ORDER_FETCH_SUCCESS) {
        return;
      }

      const visible = this.props.navigation.isFocused();

      if (!visible) {
        this.setState({ loading: false });
        return;
      }
      const { payload } = nextProps.order;
      const { condvenda, codigo } = payload;
      const messages = _.get(payload, 'mensagens') || [];
      console.log('messages', messages);
      messages.length > 0 && this.setState({ loading: false });
      executeActionOrOpenScreenMessage(
        messages,
        () => this.actionOrderClientError(messages, codigo),
        () => this.actionOrderClientNotError(condvenda, codigo),
      );
    })();

    (() => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== LAST_CART_REQUEST) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const currentScreen = this.props.currentScreenVisible.screen;

        if (currentScreen !== this.props.componentId) {
          this.setState({ loading: false });
          return;
        }
        const { payload } = nextProps.order;
        const messages = _.get(payload, 'mensagens') || [];

        executeActionOrOpenScreenMessage(
          messages,
          this.actionLastOrderError,
          this.actionLastOrderNotError,
        );
      }
    })();

    (() => {
      if (nextProps.syncFetch.type === this.props.syncFetch.type) {
        return;
      }
      if (
        nextProps.syncFetch.payload.statusSync !== SYNC_PROCESS_SALES &&
        nextProps.syncFetch.payload.screen === SCREEN_CLIENT_DETAIL.name
      ) {
        const { orderPending } = nextProps;
        const cartId = orderPending ? orderPending.codigo : '0';
        const condvenda = orderPending ? { ...orderPending.condvenda } : {};
        const orcamento = orderPending ? orderPending.orcamento : true;

        this.setState({ loading: true }, () => {
          const payloadOrderClient = {
            codigo: cartId,
            pkeyClient: this.state.clientCurrent.pkey,
            condvenda,
            orcamento,
          };
          this.props.dispatch(orderClient(payloadOrderClient));
          this.props.dispatch(syncUpdateScreen(''));
        });
      }
    })();
    (async () => {
      if (nextProps.order.type === this.props.order.type) {
        return;
      }
      if (this.props.order.type !== SAVE_CART_FETCH_REQUEST) {
        return;
      }
      if (nextProps.order.screen !== SCREEN_CLIENT_DETAIL.name) {
        return;
      }
      if (nextProps.order.type === ORDER_FETCH_SUCCESS) {
        const visible = this.props.navigation.isFocused();
        if (!visible) {
          return;
        }
        const { order } = nextProps;
        const { payload } = order;
        const messages = _.get(payload, 'mensagens', []);

        const isFixedPriceScale = messages.some(
          msg => msg.codigo === FAMILY_SCALE_VALIDATION,
        );

        const actionMessagesNotError = isFixedPriceScale
          ? this.updateCartAfterMessagesScreen
          : this.actionSaveOrderNotError;

        executeActionOrOpenScreenMessage(
          messages,
          this.actionSaveOrderError,
          () => actionMessagesNotError(payload.codigo),
        );
      }
    })();
  }

  navigationButtonPressed({ buttonId }) {
    if (buttonId === 'addCart') {
      const currentScreen = this.props.currentScreenVisible.screen;
      if (currentScreen === this.props.componentId) {
        this.onPressOpenCart();
      }
    } else if (buttonId === 'freeProcesses') {
      this.openFreeProcess();
    } else if (buttonId === 'externalLink') {
      this.handleOnpressNavigateExternalLink();
    }
  }

  onLocationChangeFromMap = location => {
    if (location) {
      this.setState({ location });
    }
  };

  getGPSConfigs() {
    const {
      configLib: { gps },
      task,
    } = this.props;
    const setup = task && task.setup ? JSON.parse(task.setup) : {};
    const configs = GpsHelper.getGpsConfigsByLibAndActivity(gps, setup, 'in');
    return configs;
  }

  setLeftButtons() {
    this.props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => {
          this.props.navigation.pop();
        }}
        >
          <Image
            source={Images.iconBack}
            style={{ tintColor: '#f0f0f0', height: 25, width: 25, left: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  }

  setRightButtons() {
    const { client, clientCurrent, task, configLib } = this.props;
    let canDoOrder = true;
    canDoOrder = this.props.route?.params?.canDoOrder ?? true;
    const appModules = configLib?.configuracoes?.modules;
    const canDoOrderModule = appModules?.find(module => module.id === 'appClients')?.props?.can_do_order;
    const processes = _.get(client?.current, 'process', []);
    if (canDoOrderModule || canDoOrder) {
      this.props.navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity onPress={() => {
            this.openCart();
          }}
          >
            <Image
              source={Images.iconCartPlus}
              style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
            />
          </TouchableOpacity>
        ),
      });
    }

    if (processes.length > 0 && !task) {
      if ((canDoOrderModule || canDoOrder)) {
        this.props.navigation.setOptions({
          headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => {
                this.openFreeProcess();
              }}
              >
                <Image
                  source={Images.iconChoice}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                this.openCart();
              }}
              >
                <Image
                  source={Images.iconCartPlus}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                />
              </TouchableOpacity>
            </View>
          ),
        });
      } else {
        this.props.navigation.setOptions({
          headerRight: () => (
            <TouchableOpacity onPress={() => {
              this.openFreeProcess();
            }}
            >
              <Image
                source={Images.iconChoice}
                style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
              />
            </TouchableOpacity>
          ),
        });
      }
    }

    const modules = _.get(configLib, 'configuracoes.modules', []);
    const clientModule = _.find(modules, module => module.id === 'clients');
    if (clientModule) {
      const isExternalWebview = _.get(
        clientModule,
        'props.details.external_webview',
        false,
      );
      if (isExternalWebview) {
        this.setState({ clientModule: isExternalWebview });
        if ((canDoOrderModule || canDoOrder)) {
          this.props.navigation.setOptions({
            headerRight: () => (
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity onPress={() => {
                  this.handleOnpressNavigateExternalLink();
                }}
                >
                  <Image
                    source={Images.iconExternalLink}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 20 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  this.openCart();
                }}
                >
                  <Image
                    source={Images.iconCartPlus}
                    style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 5 }}
                  />
                </TouchableOpacity>
              </View>
            ),
          });
        } else {
          this.props.navigation.setOptions({
            headerRight: () => (
              <TouchableOpacity onPress={() => {
                this.handleOnpressNavigateExternalLink();
              }}
              >
                <Image
                  source={Images.iconExternalLink}
                  style={{ tintColor: '#f0f0f0', height: 25, width: 25, right: 10 }}
                />
              </TouchableOpacity>
            ),
          });
        }
      }
    }
  }

  handleOnpressNavigateExternalLink = () => {
    const { clientModule, clientCurrent } = this.state;
    if (clientModule.secret && clientModule.base_url) {
      alert('Não é possível acessar a página externa');
      /*  NavigationHelper.push(
         this.props.componentId,
         SCREEN_WEBVIEW.name,
         clientCurrent.nome,
         {
           clientModule,
           clientCurrent,
         },
       );
       this.props.navigation.navigate('Webview', {
         clientModule,
         clientCurrent,
       }); */
    } else {
      alert('Não é possível acessar a página externa');
      /* Alert.alert(
        `${translate('ProblemAccessingThePage')}.`,
        `${translate('ProblemAccessingThePageDescription')}.`,
        [
          {
            text: `${translate('cancel')}`,
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: `${translate('pleaseTryAgain')}`,
            onPress: () => this.handleOnpressNavigateExternalLink(),
            style: 'default',
          },
        ],
        { cancelable: false },
      ); */
    }
  };

  cancelActiveOrder = async () => {
    const promiseCancelOrder = new Promise((resolve, reject) =>
      OrderModule.cancelActiveCart(resolve, reject),
    );

    try {
      await promiseCancelOrder;
      this.props.dispatch(updateStatusOpenOrder(true));
    } catch (error) {
      Snackbar.show({
        text: `${error?.message ?? 'Erro ao cancelar pedido pendente'}`,
      });
    }
  };

  validateGPSPoints() {
    const { location, clientCurrent: client } = this.state;
    const {
      requiredDemand: required,
      verifyAreaDemand: verify,
    } = this.getGPSConfigs();

    const { msgs } = GpsHelper.validateRulesByConfigs({
      required,
      verify,
      client,
      location,
    });

    return new Promise((resolve, reject) => {
      if (msgs.length) {
        Snackbar.show({ text: msgs.join('. ') });
        reject();
      } else {
        resolve();
      }
    });
  }
  pushScreen = (screen, title, props) => {
    this.props.navigation.navigate(screen.name, {
      title: title || screen?.title,
      ...props,
    });
  };

  openFreeProcess() {
    const [date] = moment()
      .format()
      .split('T');

    const activitiesScreen = new ActivitiesScreen(
      this.pushScreen,
      this.props.dispatch,
      date,
      this.props.clientCurrent,
      [],
      'customers',
    );

    activitiesScreen.handleNextScreen(this.props?.client?.current, date);
  }

  actionLastOrderError = () => {
    this.setState({ loading: false });
  };

  actionLastOrderNotError = () => {
    const currentConditionsSale = this.props.order.payload.condvenda || {};
    this.setState({ currentConditionsSale });
  };

  updateSaleOpportunity = async codigo => {
    try {
      await GeneralModule.updateOpportunityByOrderId(
        this.salesOpportunitySelected.u_pkey,
        codigo,
      );
      return false;
    } catch (error) {
      // t_vars não configurado: POLI;OPTN;UPDT
      Alert.alert(
        `${translate('SalesOpportunity')}`,
        `${translate('paramsError')}`,
      );
      return true;
    }
  };

  /* actionOrderClientError = async (messages, codigo) => {
    const messagesErrors = messages.filter(m => m.tipo === 'erro');
    const condSaleInvalid = messagesErrors.find(
      m => m.codigo === 'condicoes_venda_invalida',
    );
    if (condSaleInvalid && messagesErrors.length === 1) {
      try {
        this.showModalResume();
        await this.saveEventOpenOrder(codigo);
        this.openCartScreen();
      } catch (err) {
        console.tron.log(
          'PE_APP | file: ClientDetail.Container.js:409 | err =>',
          err,
        );
      }
    } else {
      this.pushingScreen = false;
    }
  }; */

  isContextSaleOpportunity = async codigo => {
    let oportunityCache = null;
    try {
      oportunityCache = await SaleOpportunityCache.getSaleOptnSelected();
    } catch (err) {
      console.tron.log(
        'PE_APP | file: ClientDetail.Container.js:428 | err =>',
        err,
      );
    }
    if (this.salesOpportunitySelected || oportunityCache) {
      return this.updateSaleOpportunity(codigo);
    }
    return false;
  };

  actionOrderClientError = async (messages, codigo) => {
    if (!(await this.isContextSaleOpportunity(codigo))) {
      const messagesErrors = messages.filter(m => m.tipo === 'erro');
      const condSaleInvalid = messagesErrors.find(
        m => m.codigo === 'condicoes_venda_invalida',
      );
      if (condSaleInvalid && messagesErrors.length === 1) {
        try {
          setTimeout(() => {
            this.showModalResume();
          }, 500);
          await this.saveEventOpenOrder(codigo);
          // this.openCartScreen();
        } catch (err) {
          console.log(
            'PE_APP | file: ClientDetail.Container.js:450 | err =>',
            err,
          );
        } finally {
          this.setState({ loading: false });
        }
      } else {
        this.pushingScreen = false;
      }
    }
  };

  actionOrderClientNotError = async (condvenda, codigo) => {
    if (!(await this.isContextSaleOpportunity(codigo))) {
      const { currentConditionsSale } = this.state;
      const { productsMagnitudes, configLib } = this.props;
      const showResumeFirst = _.get(
        configLib,
        'configuracoes.exibir_cvenda_abertura',
        false,
      );
      // this.setIconButtonCartPlus();

      if (
        condvenda.orgvenda !== currentConditionsSale.orgvenda ||
        productsMagnitudes.type !== PRODUCTS_MAGNITUDES_SUCCESS
      ) {
        // this.props.dispatch(setProductsMagnitudes(condvenda.orgvenda));

        await this.saveEventOpenOrder(codigo);
        if (showResumeFirst) {
          setTimeout(() => {
            this.showModalResume();
          }, 500);
        } else {
          this.openCartScreen();
        }
        // this.openCartScreen();
      } else {
        await this.saveEventOpenOrder(codigo);
        this.openCartScreen();
      }
    }
    this.setState({ loading: false });
  };

  saveEventOpenOrder(pkeyOrder) {
    const { location } = this.state;

    return new Promise((resolve, reject) =>
      OrderModule.saveEventOpenOrder(
        pkeyOrder,
        JSON.stringify(location || {}),
        resolve,
        reject,
      ),
    );
  }

  openCustomerOnMaps = () => {
    const { clientCurrent } = this.state;
    if (clientCurrent?.latitude) {
      Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${clientCurrent.latitude},${clientCurrent.longitude}`);
    }
  };

  openPhone = phone => {
    Linking.openURL(`tel:${phone}`);
  };

  async openCart() {
    console.log('openCart props', this.props);
    const { syncFetch } = this.props;
    const { statusSync } = syncFetch.payload;

    const { orderPending } = this.props.route.params || {};

    const {
      hasPendingOrder,
      orderPending: dataOrderPending,
      clientPkey,
      message,
    } = await verifyOrderPending();
    const totalOrderToString = dataOrderPending?.total?.toString();
    const totalOrder = totalOrderToString?.replace('.', ',');
    this.setState({ totalOrder: totalOrder });

    if (statusSync !== SYNC_PROCESS_SALES) {
      if (!hasPendingOrder || clientPkey === this.state.clientCurrent.pkey) {
        this.setState({ loading: true });
        const cartId = orderPending ? orderPending.codigo : '0';
        const condvenda = orderPending ? { ...orderPending.condvenda } : {};
        const orcamento = orderPending ? orderPending.orcamento : true;
        const payloadOrderClient = {
          codigo: cartId,
          pkeyClient: this.state.clientCurrent.pkey,
          condvenda,
          orcamento,
        };
        this.props.dispatch(orderClient(payloadOrderClient));
      } else {
        this.findClient(clientPkey, dataOrderPending);
        this.codigoOrderPending = dataOrderPending.codigo;
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        this.setState({ alertOrderPending: true }, () => {
          this.refModalOrderPending.open();
        });
      }
    } else {
      this.setState({ loading: false });
      this.props.dispatch(syncUpdateScreen(SCREEN_CLIENT_DETAIL.name));
    }
  }

  onPressOpenCart = onPressHelper.debounce(this.openCart);

  openCartScreen() {
    const originScreen = this.props.originScreen || null;
    this.props.dispatch(setModuleCurrent(''));
    this.props.dispatch(updateStatusOpenOrder(false));
    this.props.dispatch(setCurrentClientNoThunk(this.state.clientCurrent));
    this.props.navigation.navigate('Cart', {
      screen: SCREEN_CLIENT_DETAIL.name,
      routeByitinerary: this.props.routeByitinerary,
      actualClient: this.props.clientCurrent,
      originScreen,
    });
  }

  findClient = async (pkey, orderPending = {}) => {
    const clientFind = await this.getClientByPkey(
      pkey,
      orderPending.cliente_coringa,
    );
    this.clientOrderPending = clientFind;
  };

  showModalResume() {
    this.props.navigation.navigate('Resume', {
      comesFromClientDetail: true,
      addItem: this.addItem,
      productClick: this.productClick,
      screen: this.props.componentId,
    });
  }

  addItem = (item, quantity) => {
    const { codcliente } = this.props.order.payload.condvenda;
    const itemCopy = Object.assign({}, item);
    itemCopy.quantidade = quantity;
    const payloadAddItem = {
      clientCode: codcliente,
      itemKey: itemCopy.chave.toString(),
      item: itemCopy,
    };

    this.props.dispatch(updateItemFetch(payloadAddItem));
    this.props.dispatch(updateOrderScreen(SCREEN_CLIENT_DETAIL.name));
  };

  productClick = item => {
    const pushingScreen = this.props.pushingScreen || {};
    if (pushingScreen.status) {
      return;
    }

    this.props.dispatch(setPushingScreen(true));

    const screen =
      item.tipo === 'produto'
        ? SCREEN_PRODUCT_DETAIL.name
        : SCREEN_COMPOUND_PRODUCT.name;
    NavigationHelper.showModal(screen, item.nome, {
      addItem: this.addItem,
      product: item,
      screen,
      pageDefault: 1,
      cameByShowModal: true,
    });
  };

  renderAddressAlternativeComponent = () => {
    const { addressAlternative = false } = _.get(
      this.props,
      'configLib.configuracoes',
      {},
    );
    const { location, clientCurrent } = this.state;
    const { pkey: pkeyClient } = clientCurrent;

    return (
      <View
        style={{
          marginTop: 2,
          marginBottom: 2,
          height: 50,
          backgroundColor: '#FFFFFF',
        }}>
        <Touchable
          onPress={() => this.modalRef.open()}>
          <View>
            <Text
              style={{
                color:
                  Platform.OS === 'ios' ? color.grayDark : color.primaryColor,
                fontFamily: fonts.QuicksandBold,
                alignSelf: 'center',
                fontSize: 14,
              }}>
              Criar Endereço
            </Text>
          </View>
        </Touchable>
        <BottomSheet
          ref={ref => {
            this.modalRef = ref;
          }}
          height={600}
          onClose={this.onClose}>
          <AddrAlternativeForm
            pkeyClient={pkeyClient}
            location={location}
            onSaveReached={() => this.modalRef.close()}
          />
        </BottomSheet>
      </View>
    );
  };

  getClientByPkey = async (pkey, isJoker = false) => {
    let clie = null;
    if (isJoker) {
      try {
        const tamMemory = _.get(this.props.configLib, 'tam_memoria_paginacao');

        const resp = await listGeneric(
          emp2long('CCOR'),
          emp2long('CCPR'),
          {},
          tamMemory,
        );

        clie = resp?.data?.find(cli => cli.pkey === pkey);
      } catch (err) {
        console.log('ERROR GET CLIENTES_CORINGA =>', err.message);
      }
    } else {
      clie = this.props?.clients?.payload?.find(cli => cli.pkey === pkey);
    }
    return clie;
  };

  currentClientAndGetCart = async () => {
    const { order } = this.props;
    const currentScreen = this.props.currentScreenVisible.screen;
    if (currentScreen === this.props.componentId) {
      const pkClient = _.get(order.payload, 'condvenda.codcliente');
      const isJoker = _.get(order.payload, 'cliente_coringa', false);

      const clientFind = await this.getClientByPkey(pkClient, isJoker);

      if (clientFind) {
        this.props.dispatch(setCurrentClient(clientFind));
        this.setState({ loading: true, clientCurrent: clientFind });
        const cartId = this.props.order?.payload?.codigo;
        const condvenda = this.props.order.payload.condvenda;
        const orcamento = this.props.order?.payload?.orcamento;
        const payloadOrderClient = {
          codigo: cartId,
          pkeyClient: clientFind.pkey,
          condvenda,
          orcamento,
        };
        this.props.dispatch(orderClient(payloadOrderClient));
      }
    }
  };

  async saveCartBudget() {
    try {
      this.props.dispatch(updateOrderScreen(SCREEN_CLIENT_DETAIL.name));
      const task = this.props.taskCurrent?.current?.item;

      if (task && this.props.makeOrderModule?.payload === MODULE_ACTIVITIES) {
        const client = this.state.clientCurrent;
        const setup = task.setup ? JSON.parse(task.setup) : {};
        try {
          const location = await this.gpsManager.getCurrentPosition();
          this.location = { ...location.coords, timestamp: location.timestamp };
          const message = GpsHelper.validateRulesGPSClient(
            setup,
            client,
            this.location,
          );
          if (!_.isEmpty(message)) {
            Alert.alert('Oops!', message);
            return;
          }
        } catch (err) {
          Alert.alert('Oops!', err);
          return;
        }
      }
      const cartId =
        this.props.order?.payload?.codigo !== 0
          ? this.props.order?.payload?.codigo
          : this.codigoOrderPending;
      this.props.dispatch(saveCart(cartId));
    } catch (error) {
      console.log(
        'PE_APP | file: ClientDetail.Container.js:838 | err =>',
        error,
      );
    }
  }

  restoreOrderPending = () => {
    this.currentClientAndGetCart();
  };

  updateCartAfterMessagesScreen = () => {
    const cart = _.get(this.props, 'order.payload', '');
    const cartId = cart.codigo.toString();
    this.props.dispatch(getCartFetch(cartId));
    this.props.dispatch(setCurrentProduct({}));
  };

  actionSaveOrderNotError = codigo => {
    this.setState({ loading: false }, async () => {
      await this.eventSaveBudget(codigo);
      setTimeout(() => {
        this.props.dispatch(updateStatusOpenOrder(true));
        NavigationHelper.pop(this.props.componentId);
      }, 200);
    });
  };

  eventSaveBudget(pkeyOrder) {
    return new Promise((resolve, reject) =>
      OrderModule.eventSaveBudget(
        `${pkeyOrder}`,
        JSON.stringify(this.location || {}),
        resolve,
        reject,
      ),
    );
  }

  render() {
    const { loading, clientCurrent, location } = this.state;
    const { syncFetch } = this.props;

    const showAlert =
      syncFetch.payload.statusSync === SYNC_PROCESS_SALES &&
      syncFetch.payload.screen === SCREEN_CLIENT_DETAIL.name;

    return (
      <ClientDetail
        configLib={this.props.configLib}
        syncData={showAlert}
        loading={loading}
        item={clientCurrent}
        location={location}
        navigator={this.props.navigator}
        openMaps={this.openCustomerOnMaps}
        openPhone={this.openPhone}
        onLocationChangeFromMap={this.onLocationChangeFromMap}
        routeByitinerary={this.props.routeByitinerary}
        renderAddressAlternativeComponent={
          this.renderAddressAlternativeComponent
        }
        hasPendingOrder={this.state.alertOrderPending}
        refModalOrderPending={ref => (this.refModalOrderPending = ref)}
        totalOrder={this.state.totalOrder}
        cancelActiveOrder={this.cancelActiveOrder}
        refModalOrderPendingClose={() => this.refModalOrderPending.close()}
        restoreOrderPending={this.restoreOrderPending}
        saveCartBudget={() => this.saveCartBudget()}
        clientOrderPending={this.clientOrderPending}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    configLib: state.configLibFetch.payload,
    order: state.orderFetch,
    productsMagnitudes: state.productsMagnitudes,
    syncFetch: state.syncFetch,
    currentScreenVisible: state.currentScreenVisible,
    salesOpportunity: state.salesOpportunityFetch,
    permission: state.permissionsFetch,
    taskCurrent: state.taskCurrent,
    makeOrderModule: state.makeOrderModule,
    clients: state.clientsFetch,
    client: state.clientCurrent,
  };
}

ClientDetailContainer.propTypes = {
  configLib: PropTypes.any,
  dispatch: PropTypes.func,
  navigation: PropTypes.shape({
    pop: PropTypes.func,
    navigate: PropTypes.func,
    setOptions: PropTypes.func,
  }),
  pushingScreen: PropTypes.object,
  task: PropTypes.any,
  order: PropTypes.any,
  orderPending: PropTypes.any,
  productsMagnitudes: PropTypes.any,
  clientCurrent: PropTypes.any,
  syncFetch: PropTypes.any,
  canDoOrder: PropTypes.bool,
  routeByitinerary: PropTypes.bool,
  salesOpportunity: PropTypes.object,
  componentId: PropTypes.string,
  currentScreenVisible: PropTypes.shape({
    screen: PropTypes.string,
  }),
  clients: PropTypes.shape({
    payload: PropTypes.object,
  }),
  permission: PropTypes.shape({
    payload: PropTypes.shape({
      response: PropTypes.object,
    }),
  }),
  taskCurrent: PropTypes.shape({
    current: PropTypes.shape({
      item: PropTypes.object,
    }),
  }),
  makeOrderModule: PropTypes.shape({
    payload: PropTypes.string,
  }),
  originScreen: PropTypes.string,
};

export default activeCart(
  ClientDetailContainer,
  mapStateToProps,
);
