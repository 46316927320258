import axios from 'axios';
import { getAuthToken } from '~/services/anywhere/constants';
import { BASE_ANYWHERE_URL_PROD } from '../constants';

export const handleDestroySession = async (save = false) => {
  await destroySession(save);
  alert('Pedido finalizado! Fechando aba...');
  window.close();
};

export const destroySession = async (save = false) => {
  const token = await getAuthToken();
  return axios.delete(`${BASE_ANYWHERE_URL_PROD}/v1/api/session/`, {
    headers: { Authorization: `FSJWT ${token}` },
    data: {
      save,
    },
  });
};

export const createSession = async customerPkey => {
  const token = await getAuthToken();
  return axios.post(
    `${BASE_ANYWHERE_URL_PROD}/v1/api/session/`,
    {
      cliente_pkey: customerPkey,
    },
    {
      headers: { Authorization: `FSJWT ${token}` },
    },
  );
};
