/* @flow */

import React, { Component } from 'react';
import { ActivityIndicator, FlatList, Image, StyleSheet, Text, View } from 'react-native';
import style from '~/common/style';
import ClientsListItem from './Item/ClientsListItem';
import Touchable from 'react-native-platform-touchable';
import Images from '@assets/Images';
import * as config from '~/config/config';
import { Search } from '~/components';
import { color } from '~/common';
import EmptyView from '../../components/EmptyView';
import translate from '../../locales';
import ClientsModulesPlaceholder from './components/ClientsModulesPlaceholder';
import BottomAlert from '../../components/BottomAlert';
import PropTypes from 'prop-types';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';

const styles = StyleSheet.create({
  addUserImage: {
    width: 20,
    height: 20,
    marginRight: 10,
    tintColor: config.Colors.primaryColor,
  },
  addUserContainer: {
    backgroundColor: config.Colors.primaryColor,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
  },
  addUserButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginHorizontal: 40,
    paddingVertical: 6,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
  },
  addUserText: {
    color: config.Colors.primaryColor,
    fontSize: 15,
  },
});

class Clients extends Component {
  keyExtractor = (item, index) => `${item.pkey}`;

  renderItem = ({ item, index }) => {
    return (
      <ClientsListItem
        onPressItem={this.props.onPressItem}
        onOpenRegisterAgain={this.props.onOpenRegisterAgain}
        configLib={this.props.configLib}
        {...this.props}
        index={index}
        item={item}
        solicitations={this.props.solicitations}
      />
    );
  };

  renderEmpty = () => {
    const { showDates, failure, loading } = this.props;
    const emptyMessage = showDates
      ? `${translate('noCustomersThisDay')}`
      : `${translate('noCustomers')}`;
    if (loading || failure) {
      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            alignSelf: 'center',
            top: '50%',
          }}>
          <ActivityIndicator size="large" color={color.primaryColor} />
          {failure && (
            <Text style={styles.renderAlert}>{translate('waitALittle')}</Text>
          )}
        </View>
      );
    }
    return <EmptyView icon={Images.iconCustomerBig} message={emptyMessage} />;
  };

  render() {
    const {
      onPressAdd,
      onChangeText,
      loading,
      showSearch,
      clients,
      showButtonNewRegister,
      handleSendRequest,
      onHideModal,
      modalVisible,
    } = this.props;

    if (loading && clients.length === 0) {
      return <ClientsModulesPlaceholder />;
    }
    return (
      <View style={style.container}>
        <ModalOverlay visible={loading} closeOnTouchOutside />
        <ActivityIndicator
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999,
          }}
          animating={loading}
          size="large"
        />
        {onPressAdd &&
          showButtonNewRegister && (
            <View style={styles.addUserContainer}>
              <Touchable
                onPress={onPressAdd}>
                <View style={styles.addUserButton}>
                  <Image
                    source={Images.iconAddUser}
                    style={styles.addUserImage}
                  />
                  <Text style={styles.addUserText}>
                    {translate('newRegister')}
                  </Text>
                </View>
              </Touchable>
            </View>
          )}
        {showSearch ? (
          <Search onChangeText={onChangeText} onClose={onChangeText} />
        ) : null}
        {clients && clients.length > 0 ? (
          <FlatList
            data={clients}
            removeClippedSubviews
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
          />
        ) : (
          this.renderEmpty()
        )}

        <BottomAlert
          setRef={ref => {
            this.bottomAlert = ref;
          }}
          title={`${translate('changeLocation')}`}
          description={`${translate('changingCustomerLocation')}.\n${translate(
            'wantToSubmit',
          )}`}
          mainButtonTitle={`${translate('send')}`}
          secondaryButtonTitle={`${translate('cancel')}`}
          hasSecondaryButton
          onPressSecondaryButton={() => this.bottomAlert.close()}
          onPressMainButton={() => {
            this.bottomAlert.close();
            handleSendRequest();
          }}
          visible={modalVisible}
          onClose={onHideModal}
        />
      </View>
    );
  }
}

Clients.propTypes = {
  configLib: PropTypes.object.isRequired,
  showButtonNewRegister: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  useAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
  showDates: PropTypes.bool,
  solicitations: PropTypes.array.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onPressAdd: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onPressItem: PropTypes.func.isRequired,
  onOpenRegisterAgain: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onLoadFinished: PropTypes.func.isRequired,
  handleSendRequest: PropTypes.func.isRequired,
  onHideModal: PropTypes.func.isRequired,
  loadMoreClients: PropTypes.func.isRequired,
};

Clients.defaultProps = {
  showDates: true,
};

export default Clients;