import React, { Component } from 'react';
import {
    Text,
    View,
    TouchableOpacity,
    KeyboardAvoidingView,
    FlatList
} from 'react-native';
import FloatingTitleTextInput from '~/components/FloatingTitleTextInput';
import { color } from '../../common';
import RBSheet from '../BottomSheet/index';

import TextUtils from '~/utils/TextUtils';
import { Button } from '../index';
import { filterByTextFields, getFieldsSearch } from '../../utils/sorterFilter';
import styles from './styles.native';
import translate from '../../locales';
import EmptyView from '../../components/EmptyView';
import Images from '../../../assets/Images';
import _ from 'lodash';

import PropTypes from 'prop-types';

class RawBottomSheet extends Component {
    state = {
        itensSearch: [],
        valueKeyExtract: null,
        searchObject: false,
        search: '',
    };

    componentDidMount() {
        const { itens } = this.props;
        if (itens && itens[0]) {
            const searchObject = typeof itens[0] === 'object';
            const valueKeyExtract = typeof itens[0] === 'object' ? itens[0][0] : null;

            this.setState({
                itensSearch: itens,
                valueKeyExtract,
                searchObject,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.itens, this.props.itens)) {
            this.setState({ itensSearch: this.props.itens });
        }
    }

    onChangeText = search => {
        const { itens } = this.props;
        const { searchObject } = this.state;

        let itensSearch = [];
        if (searchObject) {
            const fields = getFieldsSearch(itens);
            itensSearch = filterByTextFields(itens, fields, search);
        } else {
            itensSearch = itens.filter(item =>
                TextUtils.slugfy(item).includes(TextUtils.slugfy(search)),
            );
        }

        this.setState({ itensSearch, search });
    };

    onSelectOption = item => {
        const { onSelectOption } = this.props;

        if (onSelectOption) {
            onSelectOption(item);
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    };

    onPress = item => {
        const { onSelectOption } = this.props;
        if (onSelectOption) {
            const realIndex = _.findIndex(this.props.itens, i => i === item);
            onSelectOption(item, realIndex);
        }
    };

    setRefRawBottomSheet = ref => {
        const { setRef } = this.props;
        if (setRef) {
            setRef(ref);
        }
    };

    keyExtractor = (item, index) => {
        const { valueKeyExtract } = this.state;
        return valueKeyExtract ? `${valueKeyExtract}-${index}` : `${item}-${index}`;
    };

    ItemSeparatorComponent = () => <View style={styles.separator} />;

    renderItem = ({ item, index }) => {
        const { renderComponentItem } = this.props;

        return (
            <TouchableOpacity
                style={styles.listButton}
                onPress={() => this.onPress(item)}>
                {renderComponentItem ? (
                    renderComponentItem(item, index)
                ) : (
                    <Text style={styles.listLabel}>{item}</Text>
                )}
            </TouchableOpacity>
        );
    };

    render() {
        const {
            showSearch,
            height,
            containerStyle,
            showConfirmButton,
            onPressConfirm,
            itemSelected,
            onPressRemoveAll,
            onPressConditionsPriceTerm,
            showPriceTermConditionsBtn,
            showPriceConditions,
            btnTitle = 'OK',
            title
        } = this.props;
        const { itensSearch, search } = this.state;

        return (
            <KeyboardAvoidingView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={{ flex: 1 }}>
                <View
                    style={{
                        flex: 1,
                    }}>
                    <RBSheet
                        ref={ref => this.setRefRawBottomSheet(ref)}
                        height={height || 450}
                        onClose={this.onClose.bind(this)}
                    >
                        <View style={styles.listContainer}>
                            {title ? <Text style={styles.listTitle}>{title}</Text> : null}
                            {showSearch && (
                                <FloatingTitleTextInput
                                    placeholder={translate('search')}
                                    value={search}
                                    tintColor={color.primaryColor}
                                    onChangeText={this.onChangeText}
                                />
                            )}
                            {itensSearch && itensSearch.length > 0 ? (
                                <FlatList
                                    keyExtractor={this.keyExtractor}
                                    data={itensSearch}
                                    extraData={itemSelected}
                                    renderItem={this.renderItem}
                                    contentContainerStyle={containerStyle}
                                    ItemSeparatorComponent={this.ItemSeparatorComponent}
                                />
                            ) : (
                                <EmptyView
                                    icon={Images.iconBoxEmpty}
                                    message={`${translate('noItems')}...`}
                                />
                            )}
                            {showConfirmButton &&
                                onPressRemoveAll && (
                                    <Button
                                        flat
                                        title={translate('clearSelectedProducts')}
                                        onPress={onPressRemoveAll}
                                        color={color.dangerColor}
                                        style={{ marginBottom: 10 }}
                                    />
                                )}
                            {showConfirmButton && (
                                <Button title={btnTitle} onPress={onPressConfirm} />
                            )}
                            {showPriceTermConditionsBtn && showPriceConditions ? (
                                <Button
                                    title={translate('conditionsPriceTerm')}
                                    onPress={onPressConditionsPriceTerm}
                                />
                            ) : null}
                        </View>
                    </RBSheet>
                </View >
            </KeyboardAvoidingView >
        );
    }
}

RawBottomSheet.propTypes = {
    containerStyle: PropTypes.object,
    height: PropTypes.number,
    itens: PropTypes.array,
    itemSelected: PropTypes.any,
    onPressConfirm: PropTypes.func,
    onSelectOption: PropTypes.func,
    renderComponentItem: PropTypes.func,
    setRef: PropTypes.func,
    showConfirmButton: PropTypes.bool,
    showSearch: PropTypes.bool,
    onClose: PropTypes.func,
    onPressRemoveAll: PropTypes.func,
    onPressConditionsPriceTerm: PropTypes.func,
    showPriceTermConditionsBtn: PropTypes.bool,
    showPriceConditions: PropTypes.bool,
    btnTitle: PropTypes.string,
};

export default RawBottomSheet;
