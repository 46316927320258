import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'react-native';

import {
  GENERATE_DATA_FINALLY,
  SYNC_FINALLY,
  SYNC_STARTED,
  USER_NOT_AUTHORIZED,
  USER_PASSWD_EXPIRED,
} from './constants/Sync';
import ServicesModule from './modules/ServicesModule';
import Sync from './services/resources/Sync';
import SyncConfig from './services/resources/SyncConfig';
import { getConfig } from './store/configFetch/slice';
import { configFetch } from './store/configLibFetch/slice';
import store from './store/index';
import SQLiteHelper from './database/SQLiteHelper';
import RNExitApp from 'react-native-exit-app';
import MainNav from './MainNav';
import translate from './locales';
import UploadAtividades from './services/resources/UploadAtividades';
import CacheAccount from './controllers/Accounts/Cache/CacheAccount';
import { sendCapabilities } from './services/resources/SyncUtil';
import CacheDataController from '~/utils/CacheDataController';
import {
  syncUpdateTimeAndStatus,
  syncUpdateStatus,
  showProgressSync,
  updateProgressSync,
  synUpdateCodeError,
} from './store/syncFetch/slice';

import { isChildWindow } from '~/services/anywhere/constants';

const TAG = 'TaskSync';

const alertMessage = async (message, gotoHome) => {
  Alert.alert(
    'Ops!',
    `${message}`,
    [
      {
        text: 'Ok',
        onPress: async () => {
          if (gotoHome) {
            SQLiteHelper.dropDatabase()
              .then(async () => {
                store.dispatch(syncUpdateTimeAndStatus(SYNC_FINALLY));
                ServicesModule.syncUpdateStatusService(SYNC_FINALLY);
                await AsyncStorage.clear();
                ServicesModule.stopSyncService();
                RNExitApp.exitApp();
              })
              .catch(() =>
                Alert.alert('Ops!', `${translate('errorExitPoliEquipes')}`),
              )
              .finally(() => MainNav.startLogin());
          } else {
            store.dispatch(syncUpdateTimeAndStatus(SYNC_FINALLY));
            ServicesModule.syncUpdateStatusService(SYNC_FINALLY);
          }
        },
      },
    ],
    { cancelable: false },
  );
};

const progress = data => {
  store.dispatch(updateProgressSync(data));
};

export const syncUpload = async () => {
  if (isChildWindow()) {
    return;
  }
  await Sync.uploadOnly(progress);
};

const finishSync = () => {
  store.dispatch(syncUpdateTimeAndStatus(SYNC_FINALLY));
  ServicesModule.syncUpdateStatusService(SYNC_FINALLY);
};

const canSync = () => {
  if (isChildWindow()) {
    return false;
  }

  const hasCart =
    store.getState().orderFetch.payload?.codigo !== 0 &&
    store.getState().orderFetch.payload?.codigo !== undefined;
  const cartScreens = [
    'Cart',
    'Resume',
    'ProductDetail',
    'Observations',
    'SalesConditionsChild',
    'CompoundProducts',
    'Incentivos',
    'PriceTermConditions',
    'ComboDetail',
    'Combos',
    'Rewards',
    'ProductTabs',
  ];
  // This exists to prevent moments that "exists" a open cart but the user isnot in the cart flow.
  const isInCart = cartScreens.includes(
    store.getState().currentScreenVisible.screenName,
  );
  if (store.getState().currentScreenVisible.screenName === undefined) {
    return true;
  }

  return !hasCart || !isInCart;
};

export const sync = async (background = true) => {
  const { syncFetch } = store.getState();
  const { statusSync, statusData } = syncFetch.payload;

  let syncFinaly = true;

  if (!canSync()) {
    console.log('Cannot sync because exists an Cart in edition, skipping');
    return;
  }

  if (statusSync === SYNC_FINALLY && statusData === GENERATE_DATA_FINALLY) {
    try {
      store.dispatch(showProgressSync(true));
      store.dispatch(syncUpdateStatus(SYNC_STARTED));
      await Sync.sync(progress);

      store.dispatch(configFetch());

      const syncConfig = new SyncConfig();
      await syncConfig.sync();
      const cacheAccount = new CacheAccount();
      const dataAccount = await cacheAccount.getDataAccount();

      let dataUserDevice = await cacheAccount.getUserDevice();
      console.log('LFS dataAccount', dataAccount);
      const browserInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        cookiesEnabled: navigator.cookieEnabled,
        online: navigator.onLine,
      };

      dataUserDevice = {
        ...dataUserDevice,
        browserInfo,
      };
      console.log('LFS dataUserDevice', dataUserDevice);
      const uploadAtividades = new UploadAtividades(
        dataAccount,
        dataUserDevice?.browserInfo,
        () => console.log('LFS upload fotos finished'),
        error => console.log('LFS ERROR upload fotos', error),
      );
      uploadAtividades.upload();

      store.dispatch(getConfig());
      finishSync();
    } catch (error) {
      console.log('Error in sync', error);
      // alert(error);
      syncFinaly = false;

      if (
        error.code === USER_NOT_AUTHORIZED ||
        error.code === USER_PASSWD_EXPIRED
      ) {
        if (!background) {
          await alertMessage(error.message, true);
        } else {
          finishSync();
        }
      } else {
        let { message } = error;
        const goToHome = false;

        if (goToHome) {
          message = `${translate('needToDoLoginAgain')}`;
        } else {
          store.dispatch(configFetch());
        }

        if (!background && goToHome) {
          await alertMessage(message, goToHome);
        } else {
          finishSync();
        }
      }

      if (!background) {
        store.dispatch(synUpdateCodeError(error.code));
      } else {
        finishSync();
      }
    } finally {
      store.dispatch(showProgressSync(false));
      await sendCapabilities();
    }
  }

  return syncFinaly;
};

export const tasksSync = async (background = true) => {
  CacheDataController.removeWithCriteria('@LIST_ITINERARY');
  await sync(background);
  // await taskUpdateVersion(syncFinaly);
};

export default async () => {
  const { configLibFetch } = store.getState();
  tasksSync(false);

  const isRunning = await ServicesModule.syncServiceRunnig();
  if (!isRunning) {
    ServicesModule.startSyncService(
      configLibFetch.payload.tempo_intervalo_sincronismo,
    );
  }
};
