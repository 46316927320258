import { CALL_GENERIC_PROPERTY } from '~/modules/WrappedModule';
import { callGenerateReportApi } from '~/services/anywhere/callmethod';
import { getAuthToken } from '~/services/anywhere/constants';

const ReportsApiModule = nameModule => {
  const methodByAlias = {
    getListReports: 'listarRelatorios',
    getReport: 'getRelatorio',
  };

  const generateReport = async (pkey, params, output, pathDB, pathReport) => {
    const payload = {
      report_pkey: pkey,
      format: output,
      report_binds: JSON.parse(params || '{}'),
    };

    const authToken = await getAuthToken();
    const result = await callGenerateReportApi(payload, authToken);
    return JSON.stringify(result);
  };

  const genericProperties = {};
  Object.keys(methodByAlias).forEach(
    k => (genericProperties[k] = CALL_GENERIC_PROPERTY),
  );

  return {
    getName: () => nameModule,
    methodByAlias,
    generateReport,
    ...genericProperties,
  };
};

export default ReportsApiModule;
