import React, { useEffect, useState, useRef } from 'react';
import { Image, StyleSheet, TouchableOpacity, View, Text, Dimensions, ActivityIndicator } from 'react-native';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ModalOverlay from '~/components/Web/ModalOverLay/index.web';
import AuthRoutes from './src/routes/auth.routes';
import MyTabs from './src/routes/bottomtabs.routes';

import store from './src/store/index';

import isSignedIn from './src/utils/AuthWeb';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { navigationRef } from './src/routes/RootNavigation';
import Alert from '~/components/Web/Alert';
import KeyBindingsModal from '~/components/ModalKeyBindingsMap/KeyBindingsModal';
import Images from '@assets/Images';
import { color } from '~/common';
import { getSessionPayload, isChildWindow } from '~/services/anywhere/constants';
import { fnCurrentScreen } from '~/utils/KeyBindingsUtils';

const App = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actualScreen, setActualScreen] = useState('Login');
  const [sessionKey, setSessionKey] = useState();


  const [loading, setLoading] = useState(false);

  const navigationContainerRef = useNavigationContainerRef();
  const routeNameRef = useRef();

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const onChange = ({ window }) => {
      setScreenWidth(window.width);
    };

    Dimensions.addEventListener('change', onChange);

    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  useEffect(() => {
    getSessionPayload().then((payload) => {
      setSessionKey(payload.session_key)
    })
    isSignedIn()
      .then((res) => {
        if (res) setSignedIn(true);
      })
      .catch(console.error);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };


    const handlePopState = (event) => {
      if (!window.confirm('Você realmente deseja sair ou voltar?')) {
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleCheckIsOutsideCart = (screenName) => {
    if (!childWindow) return;
    const outOfCartScreens = [
      'Home',
      'Login',
      'Profile',
      'Orders',
      'Itinerary'
    ];
    const isOutOfCart = outOfCartScreens.includes(screenName);

    setLoading(isOutOfCart)
  }

  useEffect(() => {
    handleCheckIsOutsideCart(actualScreen)
    fnCurrentScreen(actualScreen);
  }, [actualScreen]);

  const childWindow = isChildWindow();


  return (
    <Provider store={store}>
      <NavigationContainer
        ref={(ref) => {
          navigationRef.current = ref;
          navigationContainerRef.current = ref;
        }}
        onReady={() => {
          routeNameRef.current = navigationContainerRef.getCurrentRoute().name;
        }}
        onStateChange={() => {
          const currentRouteName = navigationContainerRef.getCurrentRoute().name;
          if (currentRouteName !== actualScreen) {
            setActualScreen(currentRouteName);
            fnCurrentScreen(currentRouteName);
          }
        }}
      >
        {signedIn ? MyTabs() : AuthRoutes()}
        <Alert />
        <ToastContainer autoClose={2000} />
        <KeyBindingsModal visible={modalVisible} onClose={closeModal} screen={actualScreen} />
        <TouchableOpacity style={styles.floatingButton} onPress={openModal}>
          <Image source={Images.webkeyboard} style={styles.icon} />
        </TouchableOpacity>
        {screenWidth > 1050 ? <View style={styles.sessionContainer}>
          <Text style={styles.sessionText}>{sessionKey}</Text>
        </View> : null}
        {childWindow ? <View style={styles.watermarkContainer}>
          <Text style={styles.watermark}>Sessão Derivada</Text>
        </View> : null}

        <ModalOverlay
          visible={loading}
          closeOnTouchOutside
        >
          <ActivityIndicator
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 9999,
            }}
            animating={loading}
            size="large"
          />
        </ModalOverlay>


      </NavigationContainer>
    </Provider>
  );
};

const styles = StyleSheet.create({
  watermarkContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    pointerEvents: 'none',
    alignItems: 'center',
  },
  watermark: {
    position: 'absolute',
    fontSize: 50,
    color: 'rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    transform: [{ rotate: '-45deg' }],
    opacity: 0.5,
  },

  sessionContainer: {
    position: 'absolute',
    left: 0,
    alignSelf: 'center',
    bottom: 0,
    borderRadius: 30,
    padding: 2
  },
  sessionText: {
    fontSize: 10,
    color: color.grayDarkest,
  },
  floatingButton: {
    position: 'absolute',
    right: 30,
    alignSelf: 'center',
    bottom: 30,
    backgroundColor: color.primaryColor,
    borderRadius: 30,
    paddingVertical: 10,
    paddingHorizontal: 15,
    elevation: 5,
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: 'white',
  },
});

export default App;
